import { useEffect, useRef } from "react";
// import { BiArrowToTop } from "react-icons/bi";
import {  FaRegArrowAltCircleUp } from "react-icons/fa";
import SocialMedia from "./components/common/SocialMedia";
import Home from "./components/Home";


function App() {
  const scrollUp = useRef()

  useEffect(()=>{
    document.addEventListener('scroll',()=>{
      if(window.scrollY >= 560) {
          scrollUp.current.classList.add('block')
          scrollUp.current.classList.remove('hidden')

        } else {
          scrollUp.current.classList.remove('block')
          scrollUp.current.classList.add('hidden')
        }
    })

    
  }, [])

  const handleTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  return (
    <div className="relative font-tajawal min-h-screen w-screen max-w-[100%] overflow-hidden">
      <Home />
      <SocialMedia />
      <div ref={scrollUp} onClick={() => handleTop()} className="fixed bottom-10 right-5 hidden">
        <FaRegArrowAltCircleUp className="text-4xl bg-white cursor-pointer text-main shadow-card rounded-full" />
      </div>
    </div>
  );
}

export default App;

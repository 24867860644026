import React from 'react'
import { BsTwitter, BsLinkedin, BsInstagram } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className='fixed bottom-8 left-6 z-10 flex flex-col gap-2 p-2'>
        <div className='p-2 hover:text-main hover:scale-110 w-fit rounded-full bg-white shadow-card'>
          <BsLinkedin />
        </div>
        <div className='p-2 hover:text-main hover:scale-110 w-fit rounded-full bg-white shadow-card'>
          <BsInstagram />
        </div>
        <a href='https://www.facebook.com/agency.amad' target='_blank' rel="noreferrer" className='p-2 hover:text-main hover:scale-110 w-fit rounded-full bg-white shadow-card'>
          <FaFacebookF />
        </a>
    </div>
  )
}

export default SocialMedia
import React from "react";
import { useTranslation } from "react-i18next";

// const names = ['home', 'about', 'experience', 'services', 'contact']

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="p-4 shadow-footer bg-slate-800 text-slate-100">
      {/* <h2 className='font-semibold p-2 text-xl whitespace-nowrap'>AMAD <span className='text-main'>Agency</span></h2> */}
      <p className="flex items-center justify-center sm:text-lg text-sm ">
        &#169; {t("ft")}
      </p>
    </footer>
  );
};

export default Footer;

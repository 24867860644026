import React from "react";
import { data } from "./data";
// import { FaCheck } from 'react-icons/fa'
import { motion } from "framer-motion";
import AppWrap from "../wrapper/AppWrap";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
// import { ReactComponent as Web } from '../../assets/svg/web.svg'

const cookies = new Cookies();

const Services = () => {
  const { t } = useTranslation();
  const currentLangCode = cookies.get("i18next") || "en";
  return (
    <AppWrap idName="خدماتنا" className="mt-6 ">
      <section id="services" className="bg-white py-6 px-4">
        <div className="text-center mb-5">
          <p className=" text-3xl font-semibold text-main">{t("serv_h2")}</p>
        </div>
        <div className="sm:w-[85%] w-[95%] mx-auto px-5 py-2 sm:gap-5 gap-3 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
          {data &&
            data.map((e, i) => (
              <motion.div
                whileInView={{
                  y: [-100, 0],
                  opacity: [0, 1],
                }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                }}
                key={i}
              >
                <div className="h-full p-3 w-full sm:max-w-[300px] min-w-[250] rounded-xl overflow-hidden flex flex-col gap-2 justify-start mx-auto bg-white pb-4 shadow-exp">
                  <div>{e.img}</div>
                  <div>
                    <h3 className="text-xl text-center text-slate-900 font-semibold my-3">
                      {currentLangCode === "ar" ? e.title : e.title_en}
                    </h3>
                    <p className="text-center text-slate-500">
                      {currentLangCode === "ar" ? e.disc : e.disc_en}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
        </div>
      </section>
    </AppWrap>
  );
};

export default Services;

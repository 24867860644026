import React from "react";
import { data } from "./data";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const Testmoniols = () => {
  const { t } = useTranslation();
  return (
    <section id="testmoniols" className="bg-white py-6 px-4">
      <div className="text-center mb-5">
        <p className="text-slate-600 ">{t("tes_sp")}</p>
        <h2 className="text-3xl font-semibold text-main">{t("tes_h2")}</h2>
      </div>
      <div className="px-5 py-2">
        <div className="max-w-[400px] mx-auto h-full">
          <Swiper
            // install Swiper modules
            modules={[Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {data.map(({ name, img, disc }, i) => (
              <SwiperSlide key={i} className="mb-10 h-full">
                <div className="p-7 h-full mx-5 cursor-pointer bg-slate-100 hover:bg-main/10 rounded-2xl items-center flex flex-col gap-3">
                  <div className="bg-main/30 rounded-full p-2 flex justify-center items-center overflow-hidden h-20 w-20">
                    <img
                      className="rounded-full object-cover"
                      src={img}
                      alt="avatar"
                    />
                  </div>
                  <h3 className="font-semibold my-2">{name}</h3>
                  <p className="text-slate-500">{disc}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testmoniols;

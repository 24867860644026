import React, { useEffect } from "react";
import { Cookies } from "react-cookie";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook, BiMessageSquareDetail } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";

const cookies = new Cookies();

const Nav = () => {
  const currentLangCode = cookies.get("i18next") || "en";
  let marker = document.querySelector("#item div");
  let item = document.querySelectorAll("#item a");
  const router = useLocation();
  const isActive = (r) => {
    item.forEach((link, i) => {
      if (link.attributes.href.nodeValue === router.hash) {
        currentLangCode === "ar"
          ? (marker.style.right = i * 48 + "px")
          : (marker.style.left = i * 48 + "px");
      }
    });
  };

  useEffect(() => {
    function an(e, i) {
      currentLangCode === "ar"
        ? (marker.style.right = i * 48 + "px")
        : (marker.style.left = i * 48 + "px");
    }
    item.forEach((link, i) => {
      // link.addEventListener('mouseenter', (e) => {
      //   an(e.target, i)
      // })

      link.addEventListener("click", (e) => {
        an(e.target, i);
      });
    });
  }, []);

  return (
    <div className="bg-black/50 md:hidden rounded-full backdrop-blur-sm w-max py-2 px-3 z-10 fixed left-2/4 -translate-x-2/4 bottom-6">
      <ul id="item" className="flex gap-3 relative">
        <div
          id="#marker"
          className="absolute right-0 z-[11] bg-main/90 rounded-full w-[36px] h-[36px] transition-all duration-500"
        ></div>
        <a className={`${isActive("#home")} link`} href="#home">
          <AiOutlineHome />
        </a>
        <a className={`${isActive("#about")} link`} href="#about">
          <AiOutlineUser />
        </a>
        <a className={`${isActive("#experience")} link`} href="#experience">
          <BiBook />
        </a>
        <a className={`${isActive("#services")} link`} href="#services">
          <RiServiceLine />
        </a>
        <a className={`${isActive("#contact")} link`} href="#contact">
          <BiMessageSquareDetail />
        </a>
      </ul>
    </div>
  );
};

export default Nav;

import React from 'react'
import { 
    Nav,
    Header,
    Main,
    About,
    Experience,
    Services,
    Testmoniols,
    Contact,
    Footer
} from './index'

const Home = () => {
  return (
    <>
        <Nav />
        <Header />
        <Main />
        <About />
        <Experience />
        <Services />
        <Testmoniols />
        <Contact />
        <Footer />
    </>
  )
}

export default Home
import React from "react";
import { motion } from "framer-motion";
import profile from "../../assets/profile.png";
import AppWrap from "../wrapper/AppWrap";
import { useTranslation } from "react-i18next";

// const scaleVariant = {
//     whileInView:{
//         scale: [0, 1],
//         opacity: [0, 1],
//         transition: {
//             duration: 1,
//             ease: 'easeInOut'
//         },
//     }
// }

let Main = () => {
  const { t } = useTranslation();
  return (
    <AppWrap idName="الرئيسية" className="mt-[60px]">
      <div
        id="main"
        className="relative overflow-hidden max-w-[100%] min-h-[300px] w-screen h-full flex items-center"
      >
        <div
          id="home"
          className="md:w-[80%] sm:w-[85%] w-[95%] mx-auto p-2 flex sm:flex-row flex-col h-full my-auto"
        >
          <motion.div
            whileInView={{
              x: [-100, 0],
              opacity: [0, 1],
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
            }}
            className="flex-[0.6] w-full"
          >
            <div className="w-full sm:h-full h-[200px] text-center p-2 font-semibold text-4xl flex flex-col justify-center sm:items-start items-center">
              <div className="">
                <div className="sm:w-[85%]">
                  <p>{t("main_wl")}</p>
                  <h1 className="text-5xl whitespace-nowrap font-bold text-main ml-2">
                    AMAD Agency
                  </h1>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            whileInView={{
              x: [100, 0],
              opacity: [0, 1],
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delayChildren: 0.5,
            }}
            className="flex-[0.4] relative"
          >
            <img src={profile} alt="profile" />
            <p className="absolute top-10 sm:-right-12 right-7 text-slate-700 py-1 px-3 rounded-full bg-slate-50 shadow-card">
              {t("main_p1")}
            </p>
            <p className="absolute top-24 sm:-left-24 left-7 text-slate-700 py-1 px-3 rounded-full bg-slate-50 shadow-card">
              {t("main_p2")}
            </p>
            <p className="absolute sm:top-48 top-60 sm:-left-16 left-2/4 text-slate-700 py-1 px-3 rounded-full bg-slate-50 shadow-card">
              {t("main_p3")}
            </p>
            <p className="absolute sm:top-40 top-52 sm:-right-0 right-[65%] text-slate-700 py-1 px-3 rounded-full bg-slate-50 shadow-card">
              {t("main_p4")}
            </p>
          </motion.div>
        </div>
      </div>
    </AppWrap>
  );
};

export default Main;

import React, { useEffect, useState } from "react";
// import { data } from "./data";
import { motion } from "framer-motion";
import AppWrap from "../wrapper/AppWrap";
import { Link } from "react-router-dom";

const Experience = () => {
  const [data, setdata] = useState();
  console.log(data);
  useEffect(() => {
    const fetchData = () => {
      fetch("https://back.amadagency.net/api/v1/project")
        .then((res) => res.json())
        .then((js) => {
          setdata(js.data);
        });
    };

    fetchData();
  }, []);
  return (
    <AppWrap idName="اعمالنا" className="mt-6 ">
      <section id="experience" className="bg-white pt-6 px-4">
        <div className="text-center mb-5">
          <h2 className="text-slate-600 text-base">خدماتنا في كل مكان</h2>
          <p className=" text-3xl font-semibold text-main">بعض عملائنا</p>
        </div>
        <div className="sm:w-[85%] w-[95%] mx-auto px-5 py-2 md:gap-6 sm:gap-4 gap-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
          {data &&
            data.map((e, i) => (
              <motion.div
                whileInView={{
                  scale: [0.1, 1],
                  opacity: [0, 1],
                }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                }}
                className="w-full max-w-[320px]"
                key={i}
              >
                <a
                  href={e.link}
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer block p-2  h-full bg-white rounded-2xl shadow-exp hover:scale-105 hover:-translate-y-2 hover:shadow-card transition-all duration-300 overflow-hidden"
                >
                  <div className="min-h-[160px] max-h-[320px] w-full flex items-center justify-center rounded-md">
                    <img
                      src={`https://back.amadagency.net/storage/${e.image}`}
                      alt="about1"
                      className="object-contain w-full h-full rounded-md overflow-hidden"
                    />
                  </div>
                  <div className="p-3">
                    <h3 className="font-medium sm:text-xl text-lg text-center ">
                      {e.name}
                    </h3>
                    {/* <div className='flex justify-end gap-2 m-2 font-medium'>
                      <button className='py-1 block px-3 text-slate-50 bg-main hover:text-main hover:bg-slate-200 rounded-xl transition-all duration-300'><a href={e.link}>View</a></button>
                      <button className='py-1 block px-3 bg-slate-50 text-main hover:outline outline-1 hover:outline-main rounded-xl transition-all duration-300'>Details</button>
                    </div> */}
                  </div>
                </a>
              </motion.div>
            ))}
        </div>
      </section>
    </AppWrap>
  );
};

export default Experience;

import React from 'react'
// import NavigationDots from '../common/NavigationDots'
// import SocialMedia from '../common/SocialMedia'

const AppWrap = ({children, idName, className}) => {
  return (
    <div id={idName} className={`${className}  relative`}>
        {/* <SocialMedia /> */}
        <div>
            {children}
        </div>
        {/* <NavigationDots active={idName} /> */}
    </div>
  )
}

export default AppWrap
import React, { useRef, useState } from "react";
import { MdEmail } from "react-icons/md";
import { BsMessenger, BsWhatsapp } from "react-icons/bs";
import AppWrap from "../wrapper/AppWrap";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");

  const alertRef = useRef();
  const username = useRef();
  const email = useRef();
  const phone = useRef();
  const msg = useRef();

  function send(e) {
    e.preventDefault();
    fetch(
      `https://api.telegram.org/bot5509519017:AAG7FJfZaNe45ERFstdp5VB0oS4PDBc_FC0/sendMessage?chat_id=-801577227&text= Name: ${username.current.value} %0a________________%0a Email: ${email.current.value} %0a________________%0a Phone: ${phone.current.value} %0a________________%0a Message: ${msg.current.value}`
    )
      .then(() => {
        username.current.value = "";
        email.current.value = "";
        phone.current.value = "";
        msg.current.value = "";
        alertRef.current.style.backgroundColor = "#4BB543";

        setMessage("تم الارسال بنجاح");
      })
      .catch(() => {
        alertRef.current.style.backgroundColor = "red";
        setMessage("حدث خطأ ما برجاء اعادة ارسال الرسالة مره اخرى");
      })
      .finally(() => {
        setTimeout(() => {
          setMessage("");
        }, 5000);
      });
  }

  return (
    <AppWrap idName="اتصل بنا" className="mt-6 ">
      <section id="contact" className="bg-white py-6 px-4">
        <div className="text-center mb-5">
          <h2 className="text-3xl font-semibold text-main">
            {t("contact_h2")}
          </h2>
          {/* <p className='text-slate-600 '>تواصل معنا</p> */}
        </div>
        <div dir="ltr" className="px-5 py-2">
          <div className="sm:w-[85%] w-[90%] mx-auto gap-9 flex md:flex-row flex-col">
            <div className="flex md:flex-col flex-row gap-4 justify-start sm:flex-nowrap flex-wrap">
              <div className="p-4 items-center sm:w-[180px] w-full cursor-pointer hover:bg-main/10 transition-all duration-300 bg-slate-50 border border-slate-300 rounded-xl flex flex-col">
                <MdEmail className="text-main text-3xl" />
                <h3 className="font-semibold mt-3 sm:text-lg text-lg text-slate-700">
                  Email
                </h3>
                <p className="text-3 mb-3 text-sm text-center">
                  support@amadagency.net
                </p>
                <button className="text-sm text-main font-semibold">
                  <a href="mailto:support@amadagency.net">{t("btn_send")}</a>
                </button>
              </div>
              <div className="p-4 items-center sm:w-[180px] w-full cursor-pointer hover:bg-main/10 transition-all duration-300 bg-slate-50 border border-slate-300 rounded-xl flex flex-col">
                <BsMessenger className="text-main text-3xl" />
                <h3 className="font-semibold mt-3 sm:text-lg text-lg text-slate-700">
                  Messanger
                </h3>
                <p className="text-slate-500 mb-3 text-sm text-center">amad</p>
                <button className="text-sm text-main font-semibold">
                  <a href="https://m.me/101240428972474">{t("btn_send")}</a>
                </button>
              </div>
              <div className="p-4 items-center sm:w-[180px] w-full cursor-pointer hover:bg-main/10 transition-all duration-300 bg-slate-50 border border-slate-300 rounded-xl flex flex-col">
                <BsWhatsapp className="text-main text-3xl" />
                <h3 className="font-semibold mt-3 sm:text-lg text-lg text-slate-700">
                  WhatsApp
                </h3>
                <p className="text-slate-500 mb-3 text-sm text-center">
                  01556479363
                </p>
                <button className="text-sm text-main font-semibold">
                  <a
                    href="https://api.whatsapp.com/send?phone=201556479363"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t("btn_send")}
                  </a>
                </button>
              </div>
            </div>
            <div dir="rtl" className="w-full">
              <div
                ref={alertRef}
                className={`${
                  message ? "block " : "hidden"
                } animate-pulse font-bold text-white text-center p-5 mb-5 w-full rounded-md`}
              >
                {message}
              </div>

              <form
                onSubmit={send}
                className="flex flex-col gap-3 max-w-[500px] mx-auto"
              >
                <input
                  type="text"
                  placeholder={t("contact_n")}
                  ref={username}
                  className="p-4 w-full bg-transparent outline-main rounded-2xl border border-main"
                />
                <input
                  type="email"
                  placeholder={t("contact_e")}
                  ref={email}
                  className="p-4 w-full bg-transparent outline-main rounded-2xl border border-main"
                />
                <input
                  type="text"
                  placeholder={t("contact_p")}
                  ref={phone}
                  className="p-4 w-full bg-transparent outline-main rounded-2xl border border-main"
                />
                <textarea
                  placeholder={t("contact_m")}
                  rows={8}
                  ref={msg}
                  className="p-4 w-full bg-transparent outline-main rounded-2xl border border-main"
                />
                <button className="bg-slate-800 text-slate-50 py-3 rounded-3xl">
                  {t("btn_send")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </AppWrap>
  );
};

export default Contact;

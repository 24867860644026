import { ReactComponent as Web } from "../../assets/svg/web.svg";
import { ReactComponent as Data } from "../../assets/svg/data.svg";
import { ReactComponent as Design } from "../../assets/svg/design.svg";
import { ReactComponent as Marketing } from "../../assets/svg/marketing.svg";
import { ReactComponent as Blog } from "../../assets/svg/blog.svg";
import { ReactComponent as Fix } from "../../assets/svg/fix.svg";

export const data = [
  {
    img: <Blog />,
    title: "تصميم المدونات",
    title_en: "Blog design",
    disc: "تصميم المدونات الشخصية والمواقع المتجاوبة للمؤثرين والعاملين في مجال صناعة المحتوى بغرض إظهار الهوية بطريقة أكثر  احترافية",
    disc_en:
      "Designing personal blogs and responsive websites for influencers and content industry workers in order to show identity in a more professional way",
  },
  {
    img: <Design />,
    title: "تصميم وبرمجة مواقع الشركات",
    title_en: "Design and programming of corporate websites",
    disc: "نتفهم طبيعة عملك ونشاط شركتك ونعمل على إنشاء موقع شركتك ليجعلك أقرب إلى العملاء معبرا عن علامتك التجارية وخدماتك ومنتجاتك",
    disc_en:
      "We understand the nature of your business and your company's activity, and we are working to create your company's website to bring you closer to customers, expressing your brand, services, and products.",
  },
  {
    img: <Web />,
    title: "إنشاء المتاجر الإلكترونية",
    title_en: "Creation of electronic stores",
    disc: "تصميم متجر متجاوب مع الأجهزة ومراعاة ضبط محرك البحث “SEO” والكلمات المفتاحية ومنصات الدفع الإلكتروني التي تجعلك أقرب للعميل",
    disc_en:
      "Designing a responsive store with devices, taking into account the search engine settings “SEO”, keywords, and electronic payment platforms that bring you closer to the customer",
  },
  {
    img: <Fix />,
    title: "دعم فني",
    title_en: "Technical Support",
    disc: "نهتم بخدمة ما بعد البيع مثل التحديثات الدورية والاصلاحات المجانية والدعم الفوري شامل ومتكامل على مدار الساعة",
    disc_en:
      "We care about after-sales service, such as periodic updates, free repairs, and comprehensive and integrated immediate support around the clock",
  },
  {
    img: <Data />,
    title: "تحليل البيانات",
    title_en: "data analysis",
    disc: "تساعدك التحليلات التنبؤية الفردية على معرفة عملائك بشكل أفضل والوصول إليهم بكفاءة وفعالية أكبر. تستخدم التحليلات المعززة هذه الذكاء الاصطناعي والتعلم الآلي لمساعدة الشركات على توقع طلب السوق المستقبلي من خلال تحليل البيانات التاريخية حول سلوكيات العملاء وتفضيلاتهم في مجموعات مختلفة.",
    disc_en:
      "One-to-one predictive analytics helps you know your customers better and reach them more efficiently and effectively. This augmented analytics uses artificial intelligence and machine learning to help companies anticipate future market demand by analyzing historical data about customer behaviors and preferences in different groups.",
  },
  {
    img: <Marketing />,
    title: "التسويق الرقمي",
    title_en: "digital marketing",
    disc: "نقوم بتوفير خدمات إدارة الصفحات والحملات التسويقية الإعلانية الممولة ونضمن الوصول الصحيح للعميل وجذب عملاء جدد وبناء ولاء العميل الحالي",
    disc_en:
      "We provide page management services and funded advertising marketing campaigns, ensuring correct access to the customer, attracting new customers, and building the loyalty of the existing customer.",
  },
];

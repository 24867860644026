import user1 from "../../assets/avatars/user1.png";
import user2 from "../../assets/avatars/user2.png";
import user3 from "../../assets/avatars/user3.png";
import user4 from "../../assets/avatars/user4.png";

export const data = [
  {
    name: "أ/محمد راضي",
    img: user1,
    disc: "شركة ممتازة احب اشكركم على الجهود الكبيرة في عمل الموقع وتسليمه قبل الوقت المحدد التعامل ف قمة الذوق وكان بيتابع معايا الشغل والأفكار اول ب اول علشان الموقع يطلع بالصورة الممتازة دي واشكر كل القائمين عليه",
  },
  {
    name: " أ/شاكر بدير ",
    img: user2,
    disc: "متشكر جدا على المجهود المبذول والدقة في العمل ",
  },
  {
    name: "د/سمير علي",
    img: user3,
    disc: "Honestly, it is a very good company. The work team is clear and has credibility. Thank you for your hard work",
  },
];

import React from "react";
import about from "../../assets/portfolio2.jpg";
import { motion } from "framer-motion";
import AppWrap from "../wrapper/AppWrap";
import { GiAchievement } from "react-icons/gi";
import { HiUsers } from "react-icons/hi";
import { FaLayerGroup } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <AppWrap idName="من نحن" className="mt-6">
      <section id="about" className="mt-6 px-5">
        <div className="text-center mb-5">
          <span className="text-slate-600 ">{t("about_sp")}</span>
          <h2 className="text-4xl font-semibold text-main">{t("about_h2")}</h2>
        </div>

        <div className="sm:w-[95%] w-[100%] mx-auto flex gap-4 md:flex-row flex-col">
          <motion.div
            whileInView={{
              scale: [0.2, 1],
              opacity: [0, 1],
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
            }}
            className="flex-[0.35] w-full h-full m-auto mb-3"
          >
            <div className="bg-img relative m-auto md:w-full w-[85%]">
              <img className="object-cover w-full" src={about} alt="about" />
              {/* <h2 className='absolute w-full text-center top-2/4 left-2/4 -translate-y-2/4 text-black -translate-x-2/4 bg-white py-3 font-semibold text-4xl'>pla pla pla</h2> */}
            </div>
          </motion.div>
          <motion.div
            whileInView={{
              x: [200, 0],
              opacity: [0, 1],
            }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
            }}
            className="flex-[0.65] w-full h-full m-auto"
          >
            <div className="w-full m-auto">
              {/* <div className='flex gap-4 justify-center sm:flex-nowrap flex-wrap'>
                  <div className='sm:p-4 p-2 items-center cursor-pointer w-[130px] hover:bg-slate-50 bg-main/10 border border-main rounded-xl flex flex-col gap-2'>
                    <GiAchievement className='text-main text-4xl' />
                    <h3 className='font-semibold sm:text-xl text-lg  text-center text-slate-800'>خبرة</h3>
                    <p className='text-slate-600 text-sm text-center'>+٣ </p>
                  </div>
                  <div className='sm:p-4 p-2 items-center cursor-pointer w-[130px] hover:bg-slate-50 bg-main/10 border border-main rounded-xl flex flex-col gap-2'>
                    <HiUsers className='text-main text-4xl' />
                    <h3 className='font-semibold sm:text-xl text-lg text-center text-slate-800'>Clients</h3>
                    <p className='text-slate-600 text-sm text-center'>30+ Worldwide</p>
                  </div>
                  <div className='sm:p-4 p-2 items-center cursor-pointer w-[130px] hover:bg-slate-50 bg-main/10 border border-main rounded-xl flex flex-col gap-2'>
                    <FaLayerGroup className='text-main text-4xl' />
                    <h3 className='font-semibold sm:text-xl text-lg text-center text-slate-800'>Projects</h3>
                    <p className='text-slate-600 text-sm text-center'>30+ Completed</p>
                  </div>
                </div> */}
              <p className="text-slate-500 sm:text-xl text-lg font-tajawal my-3 p-3">
                {t("about_cn1")}
                <br />
                {t("about_cn2")}
                <br />
                {t("about_cn3")}
              </p>
              <button className="bg-main/10 rounded-xl font-semibold text-main px-6 py-2 block mr-auto ml-5 hover:bg-slate-800 hover:text-slate-100">
                {t("btn_more")}
              </button>
            </div>
          </motion.div>
        </div>
      </section>
    </AppWrap>
  );
};

export default About;

import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";
import i18next from "i18next";
import Logo from "../../assets/logo/no-color.png";
import { RiArrowDownSFill } from "react-icons/ri";

const cookies = new Cookies();

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    country_code: "eg",
    dir: "rtl",
  },
];

const Header = () => {
  const { t } = useTranslation();
  const router = useLocation();
  const currentLangCode = cookies.get("i18next") || "en";
  const currentLang = languages.find((l) => l.code === currentLangCode);
  const sidRef = useRef();
  const isActive = (r) => {
    if (r === router.hash) {
      return " hd-effct ";
    }
  };
  const names = [t("hd_m"), t("hd_ab"), t("hd_wr"), t("hd_sr"), t("hd_cn")];
  const [open, setOpen] = useState(false);
  const [open_lng, setOpen_lng] = useState(false);
  const [word, setWord] = useState(currentLangCode ? currentLangCode : "en");

  const lngRef = useRef(null);

  const handleOpen = (e) => {
    setOpen(!open);
  };

  const links = names.map((e, i) => (
    <li key={i}>
      <a
        className={`${isActive(
          `#${e}`
        )} transition-all text-end block py-2 duration-200 capitalize mx-2 hover:text-main font-bold`}
        href={`#${e}`}
      >
        {e}
      </a>
    </li>
  ));

  useEffect(() => {
    document.body.dir = currentLang.dir || "ltr";
  }, [currentLang]);

  useEffect(() => {
    const closeCard = (e) => {
      if (!sidRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };
    const closeLng = (e) => {
      if (!lngRef?.current?.contains(e.target)) {
        setOpen_lng(false);
      }
    };

    document.body.addEventListener("mousedown", closeCard);
    document.body.addEventListener("mousedown", closeLng);

    return () => {
      document.body.removeEventListener("mousedown", closeCard);
      document.body.removeEventListener("mousedown", closeLng);
    };
  }, []);

  return (
    <header className="bg-white/40 z-50 fixed top-0 left-0 w-full backdrop-blur-sm py-2 shadow-hd h-[60px]">
      <div className="sm:w-[85%] w-[90%] mx-auto flex gap-3 h-full justify-between items-center">
        <img
          draggable={false}
          className="max-w-[80px] logo cursor-pointer"
          src={Logo}
          alt="Amad"
        />
        <ul className="hd-ul gap-3 md:flex hidden flex-1 justify-center items-center text-sm">
          {links}
        </ul>
        {/* langs */}
        <div
          ref={lngRef}
          onClick={() => setOpen_lng(!open_lng)}
          className="relative border border-slate-300 px-2 py-1 rounded-lg"
        >
          <h3 className="flex items-center cursor-pointer gap-3">
            <RiArrowDownSFill />
            <span>{word}</span>
          </h3>
          {open_lng && (
            <ul
              style={{ left: currentLangCode === "ar" ? 0 : "" }}
              className={`${
                currentLangCode === "ar" ? " left-0 " : " right-0 "
              }  absolute top-11 p-2 z-10 rounded-md bg-white border border-slate-400`}
            >
              {languages?.map(({ code, name, country_code }) => (
                <li
                  className="w-full"
                  onClick={(e) => {
                    setWord(code);
                    i18next.changeLanguage(code);
                    window.location =
                      window.location.pathname + window.location.search;
                  }}
                  key={country_code}
                >
                  <button
                    disabled={code === currentLangCode}
                    className={
                      `${code === currentLangCode ? "text-slate-300" : ""}  ` +
                      "flex w-full p-2 items-center rounded-md hover:bg-slate-200"
                    }
                  >
                    <span
                      style={{ opacity: code === currentLangCode ? 0.5 : 1 }}
                      className={`flag-icon flag-icon-${country_code}  mx-2`}
                    ></span>
                    {name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* drop menu */}
        <div
          ref={sidRef}
          className="relative md:hidden flex items-center justify-center"
        >
          {open === true ? (
            <button
              className="an bg-red-700 p-2 rounded-full text-slate-50"
              onClick={handleOpen}
            >
              <RiCloseLine className="text-[24px] font-bold" />
            </button>
          ) : (
            <button
              className="an2 bg-main p-2 rounded-full text-slate-50"
              onClick={handleOpen}
            >
              <RiMenu3Line className="text-[24px] font-bold" />
            </button>
          )}
          {open && (
            <div
              className={`${currentLangCode === "ar" ? " left-0 " : " right-0 "}
              sidbar absolute top-14 w-[250px] flex flex-col transition-all duration-300`}
            >
              <ul className="flex flex-col">{links}</ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
